/*
font-family: 'Poppins', sans-serif;
*/
::-moz-selection {
    background: #7d003c;
    color: #fff;
    text-shadow: none;
}

::selection {
    background: #7d003c;
    color: #fff;
    text-shadow: none;
}

@-webkit-keyframes shine {
    100% {
        left: 125%;
    }
}

@keyframes shine {
    100% {
        left: 125%;
    }
}

.shine {
    position: relative;
    overflow: hidden;
}

.shine:before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: -80%;
    width: 50%;
    height: 100%;
    background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, .3)));
    background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
    -webkit-transform: skewX(-25deg);
    -ms-transform: skewX(-25deg);
    transform: skewX(-25deg);
    opacity: 0.3;
    z-index: 1;
}

.shine:hover:before {
    -webkit-animation: shine 1s;
    animation: shine 1s;
}

/*Loader*/
#preloader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    overflow: hidden;
    z-index: 9999;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
}

#loader {
    display: block;
    position: relative;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #fafafa;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

#loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #fafafa;
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
}

#loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #fafafa;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/*Slick Slider Css Start*/
.slick-slider {
    position: relative;
    display: block;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
    min-width: 100%;
}

.slick-list.dragging {
    cursor: pointer;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    min-width: 100%;
    display: flex;
    display: -webkit-flex;
}

.slick-track:before,
.slick-track:after {
    display: table;
    content: "";
}

.slick-track:after {
    clear: both;
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    display: none;
    min-height: 1px;
    flex-grow: 1;
    flex: inherit;
}

.slick-initialized .slick-slide {
    display: block;
    list-style: none;
}

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}

.slick-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    margin-top: -13px;
    z-index: 2;
    padding: 0;
    width: 26px;
    height: 26px;
    text-align: center;
    font-size: 0;
    background: #F8F8F8;
    border: 0;
    color: #fff;
    cursor: pointer;
    opacity: 1;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -moz-transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.slick-arrow:before {
    display: block;
    content: "";
    width: 8px;
    height: 8px;
    border-left: 1px solid #000;
    border-bottom: 1px solid #000;
    transform: rotate(45deg);
    position: absolute;
    left: 4px;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.slick-arrow.slick-next:before {
    transform: rotate(-135deg);
    left: 0;
}

.slick-arrow.slick-prev {
    left: 0;
}

.slick-arrow.slick-next {
    right: 0;
}

.slick-arrow:hover {
    background: #F2A200;
}

.slick-arrow:hover:before {
    border-color: #fff
}

.slick-arrow.slick-disabled {
    opacity: 0.2 !important;
}

.slick-dots {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 0;
    bottom: 20px;
    text-align: center;
}

.slick-dots li {
    margin: 0 4px;
    padding: 0;
    list-style: none;
    display: inline-block;
    vertical-align: top;
}

.slick-dots li button {
    display: block;
    width: 12px;
    height: 12px;
    font-size: 0;
    padding: 0;
    background: none;
    border: 1px solid #F2A200;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    cursor: pointer;
    position: relative;
    -moz-transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}

.slick-dots li button:before {
    display: block;
    content: "";
    width: 4px;
    height: 4px;
    background: #F2A200;
    position: absolute;
    left: 3px;
    top: 3px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    cursor: pointer;
    -moz-transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    opacity: 0
}

.slick-dots li.slick-active button:before {
    opacity: 1;
}

.slick-dots li.slick-active button {
    border-color: #F2A200;
}
.chat-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color:#7D003C;
    color: #fff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000; /* Ensures it's above other content */
}

.chat-icon:hover {
    background-color: #F2A200;
}

/* Chat Overlay */
.chat-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); /* Transparent dark background */
    display: flex;
    align-items: right;
    justify-content: right;
    z-index: 999; /* Above all other content */
}

/* Chat Box */
.chat-box {
    background: white;
    width: 400px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    padding: 20px;
    position: relative;
}

.chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.chat-header button {
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 16px;
}

.chat-header button:hover {
    color: red;
}
/*========== Mobile Menu STYLE End==============*/
/*Mobile Menu Css Start*/
.mobi-iconbox {
    display: none;
    width: 24px;
    height: 28px;
    position: fixed;
    right: 20px;
    top: 26px;
    z-index: 40;
    transition: all 0.4s ease;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.menuicon {
    display: block;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.icon-bar {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    min-width: 100%;
    height: 20px;
    position: relative;
    cursor: pointer;
}

.icon-bar:before,
.icon-bar:after {
    display: block;
    content: "";
    width: 100%;
    height: 2px;
    background: #fff;
    position: absolute;
    left: 0;
    top: 0;
    -moz-transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.icon-bar:after {
    top: auto;
    bottom: 0;
    width: 70%;
}

.icon-bar .line {
    display: block;
    width: 80%;
    height: 2px;
    background: #fff;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto 0;
    -moz-transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.icon-bar.active .line {
    width: 1px;
    opacity: 0;
}

.icon-bar.active:before {
    transform: rotate(45deg);
    top: 10px;
    background: #fff;
}

.icon-bar.active:after {
    transform: rotate(-45deg);
    bottom: 8px;
    background: #fff;
    width: 100%;
}

.mobile-menu-open .mobilemenuicon .menuicon {
    margin-left: auto;
}

/*Mobile Menu Css End*/
/* ===== RESET ================================================== */
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
blockquote,
big,
body,
button,
center,
canvas,
caption,
cite,
code,
command,
datalist,
dd,
del,
details,
dfn,
dl,
div,
dt,
embed,
fieldset,
figcaption,
figure,
font,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
keygen,
label,
legend,
li,
meter,
nav,
object,
ol,
output,
p,
pre,
progress,
q,
s,
samp,
section,
small,
source,
strike,
sub,
sup,
table,
tbody,
tfoot,
thead,
th,
tr,
tdvideo,
tt,
u,
ul,
var {
    background: transparent;
    border: 0 none;
    margin: 0;
    padding: 0;
    line-height: 16px;
    vertical-align: baseline;
    outline: none;
    font-size: 100%;
    font-family: inherit;
    font-weight: 400;
    font-style: normal;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
q:before {
    content: '';
    content: none;
}

blockquote:after,
q:after {
    content: '';
    content: none;
}

ins {
    text-decoration: none;
}

abbr[title],
dfn[title] {
    border-bottom: 1px dotted;
    cursor: help;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

input,
select {
    vertical-align: middle;
    margin: 0;
}

ol,
ul,
li {
    list-style: none;
}

html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

/* ===== GLOBAL ================================================== */
body {
    margin: 0;
    padding: 0;
    background: #fff;
    font-family: 'Poppins', sans-serif, Arial;
    font-size: 16px;
    color: #000;
    line-height: 24px;
    font-weight: 400;
    overflow-x: hidden;
    -moz-transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}

:focus,
button:focus {
    outline: none;
}

a {
    text-decoration: none;
    color: #7D003C;
    -moz-transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}

a:hover {
    text-decoration: none;
    color: #F2A200;
}

::-webkit-input-placeholder {
    color: #1E2248;
    opacity: 0.5;
}

:-moz-placeholder {
    color: #1E2248;
    opacity: 0.5;
}

/* Firefox 18- */
::-moz-placeholder {
    color: #1E2248;
    opacity: 0.5;
}

/* Firefox 19+ */
:-ms-input-placeholder {
    color: #1E2248;
    opacity: 0.5;
}

img {
    max-width: 100%;
    height: auto;
    border: none;
    outline: none;
}

b,
strong {
    font-weight: 700;
}

/* Ul Li & Ol Li CSS */
ul,
ol {
    margin: 0 0 20px;
    padding: 0;
}

ul ul,
ol ol,
ul ol,
ol ul {
    margin-top: 15px;
}

ul li,
ol li {
    margin: 0;
    padding: 0;
    font-size: 16px;
    line-height: 28px;
    color: #222;
    font-weight: 400;
    letter-spacing: 0;
}

.entry-content ul {
    padding: 0;
    margin: 0 0 20px;
}

.entry-content ol {
    padding: 0;
    margin: 0 0 20px;
    list-style: none;
    counter-reset: my-awesome-counter;
}

.entry-content ul li {
    position: relative;
    padding: 0 0 0 24px;
    margin: 0 0 10px;
}

.entry-content ul li:before {
    content: "\f00c";
    font-family: 'Line Awesome Free';
    font-weight: 900;
    position: absolute;
    left: 0;
    top: 0;
    width: 10px;
}

.entry-content ul li li {
    list-style: disc;
}

.entry-content ol li {
    counter-increment: my-awesome-counter;
    margin: 0 0 10px;
}

.entry-content ol li::before {
    content: counter(my-awesome-counter) ". ";
    font-weight: 400;
}

/*Heading CSS*/
h1,
.h1,
h2,
.h2,
h3,
h4,
h5,
h6 {
    color: #222;
    line-height: 1.4;
    font-weight: 500;
    font-family: 'Poppins', sans-serif, Arial;
    letter-spacing: 0;
    margin: 0 0 15px;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    color: #F2A200;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
    color: #F2A200;
}

h1,
.h1 {
    font-size: 40px;
    font-weight: 700;
    margin: 0 0 24px;
}

h2,
.h2 {
    font-size: 30px;
    font-weight: 700;
    margin: 0 0 20px;
}

h3 {
    font-size: 24px;
}

h4 {
    font-size: 20px;
}

h5 {
    font-size: 18px;
}

h6 {
    font-size: 16px;
}

/* Paragraph CSS */
p {
    font-size: 16px;
    line-height: 28px;
    color: #222;
    font-weight: 400;
    letter-spacing: 0;
    margin: 0 0 15px;
}

hr {
    border: none;
    margin: 20px 0;
    background: #222;
    height: 1px;
    width: 100%;
    clear: both;
    opacity: 0.5;
}

blockquote {
    border-left: 5px solid #eee;
    font-size: 16px;
    margin: 0 0 20px;
    padding: 10px 20px;
}

/*table structure*/
table,
.entry-content table {
    border-right: 1px solid #f1f1f1;
    border-top: 1px solid #f1f1f1;
}

table th,
table td,
.entry-content th,
.entry-content table th,
.entry-content td,
.entry-content table td {
    padding: 12px 15px;
    background: #fff;
    border-left: 1px solid #f1f1f1;
    border-bottom: 1px solid #f1f1f1;
    text-align: left;
    font-size: 16px;
    line-height: 24px;
    color: #222;
    font-weight: 400;
    vertical-align: middle;
}

table th,
.entry-content table th {
    color: #000;
    font-weight: 400;
    background: #f1f1f1;
}

.table-striped th,
.table-striped td {
    position: relative;
}

.table-striped tbody tr:nth-of-type(even) td {
    background: #d4d5d6;
}

/*Form Input Field*/
input,
textarea,
select {
    background: #fff;
    border: 1px solid rgba(103, 103, 103, 0.15);
    width: 100%;
    padding: 2px 15px;
    margin: 0;
    font-size: 14px;
    color: #2F2925;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
    font-family: 'Poppins', sans-serif, Arial;
    height: 42px;
    outline: none;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

input[type="button"],
input[type="submit"] {
    cursor: pointer;
    width: auto;
    height: auto;
}

input[type="checkbox"],
input[type="radio"] {
    border: none;
    width: auto;
    height: auto;
}

textarea {
    height: auto;
    padding: 15px;
    min-height: 100px;
    resize: none;
}

input[type="button"],
input[type="submit"],
input[type="image"],
input[type="file"],
input[type="search"] {
    -webkit-appearance: none;
}

input#addressSubmit {
    -webkit-appearance: none !important;
}

input[type="radio"],
input[type="checkbox"] {
    -webkit-appearance: normal;
}

.checkbox,
.radiobox {
    display: inline-block;
    position: relative;
    padding-left: 28px;
    font-size: 15px;
    line-height: 18px;
    color: #1E2248;
    font-weight: 500;
    cursor: pointer;
}

.checkbox input,
.radiobox input {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    height: 18px !important;
    width: 18px !important;
    padding: 0 !important;
}

.checkbox .checkmark {
    display: block;
    width: 18px;
    height: 18px;
    border: 2px solid #d9d9d9;
    position: absolute;
    left: 0;
    top: 0;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -moz-transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}

.checkmark:before {
    display: block;
    content: "\f00c";
    font-family: 'Line Awesome Free';
    font-weight: 900;
    position: absolute;
    left: 1px;
    right: 0;
    top: 0;
    text-align: center;
    font-size: 14px;
    color: #1E2248;
    line-height: 14px;
    padding-left: 0;
    text-shadow: 0px 0px #1E2248;
    opacity: 0;
    -moz-transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}

.checkbox input:checked~.checkmark {
    background: #fff;
    border-color: #1E2248;
}

.checkbox input:checked~.checkmark:before {
    opacity: 1;
}

.radiobox .checkmark {
    display: block;
    width: 18px;
    height: 18px;
    background: #fff;
    border: 2px solid #d9d9d9;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
}

.radiobox .checkmark:before {
    display: none;
}

.radiobox .checkmark:after {
    display: block;
    content: "";
    background: #F2A200;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    position: absolute;
    left: 2px;
    right: 2px;
    top: 2px;
    bottom: 2px;
    opacity: 0;
}

.radiobox input:checked~.checkmark:after {
    opacity: 1;
}

.radiobox input:checked~.checkmark {
    border-color: #F2A200;
}

.selectbox {
    position: relative;
    background: #F7F6F4;
    border: 1px solid #000;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.selectbox select {
    background: none;
    border: 0;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #000;
    text-transform: uppercase;
    padding: 0 40px 0 20px;
    width: inherit;
    height: 40px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    z-index: 1;
}

.selectbox:after {
    position: absolute;
    top: 0;
    right: 0;
    content: "";
    z-index: 0;
    width: 40px;
    height: 43px;
    border: 0;
    -moz-transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}

.select-box .selectbox {
    border: 0;
    width: 100%;
}

/* Button styling */
.button,
.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 14px 24px;
    background: rgba(148, 197, 60, 0.0);
    border: 1px solid #7D003C;
    color: #7D003C;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif, Arial;
    text-transform: none;
    cursor: pointer;
    letter-spacing: 0.02em;
    text-align: center;
    position: relative;
    min-width: 140px;
    -moz-transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    border-radius: 40px;
    overflow: hidden;
}

.button:hover,
.btn:hover,
.button:focus,
.btn:focus {
    background: #F2A200;
    border-color: #F2A200;
    color: #fff;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.16);
}

.primary {
    background: #7d003c;
    border-color: #7d003c;
    color: #fff;
}

.primary:hover,
.primary:focus {
    background: #F2A200;
    border-color: #F2A200;
    color: #fff;
}

.secondary {
    background: #F2A200;
    border-color: #F2A200;
    color: #fff;
}

.secondary:hover,
.secondary:focus {
    background: #7d003c;
    border-color: #7d003c;
    color: #fff;
}

.blue-btn {
    background: #1E2248;
    border-color: #1E2248;
    color: #fff;
}

.blue-btn:hover,
.blue-btn:focus {
    background: #F2A200;
    border-color: #F2A200;
    color: #fff;
}

.blue-bdr {
    background: #fff;
    border-color: #1E2248;
    color: #1E2248;
}

.blue-bdr:hover,
.blue-bdr:focus {
    background: #1E2248;
    border-color: #1E2248;
    color: #fff;
}

.white-btn {
    background: #fff;
    border-color: #fff;
    color: #000;
}

.white-btn:hover,
.white-btn:focus {
    background: #7d003c;
    border-color: #7d003c;
    color: #fff;
}

.white-bdr {
    border-color: #fff;
    color: #fff;
}

.white-bdr:hover {
    background: #fff;
    border-color: #fff;
    color: #7d003c;
}

.gray-btn {
    background: #F7F7F7;
    border-color: #F7F7F7;
    color: #222;
}

.gray-btn:hover {
    background: #d9d9d9;
    border-color: #d9d9d9;
    color: #000;
}

.white-hover:hover {
    background: #fff;
    border-color: #fff;
    color: #7d003c;
}

.white-hover:hover i {
    color: #7d003c;
}

.btn.small {
    padding: 9px 16px;
    font-size: 14px;
}

.btn i {
    font-size: 18px;
}

.btn2 {
    background: #FFEDF6;
    border-color: #FFEDF6;
    color: #7D003C;
}

.btn2:hover,
.btn2:focus {
    background: #7D003C;
    border-color: #7D003C;
    color: #fff;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
    background: #F2A200;
    border-color: #F2A200;
    color: #fff;
}

.container {
    margin: 0 auto;
    width: 100%;
    max-width: 1240px;
    padding: 0 20px;
}

.text-center {
    text-align: center;
}

section {
    position: relative;
}

section *:last-child {
    margin-bottom: 0;
}

.ml-auto,
.mx-auto {
    margin-left: auto !important;
}

.mr-auto {
    margin-right: auto !important;
}

.mr-0 {
    margin-right: 0 !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.ml-2 {
    margin-left: 5px;
}

.mr-2 {
    margin-right: 5px;
}

.d-flex {
    display: -ms-flexbox !important;
    display: flex !important;
}

.hide {
    display: none;
}

.v-center {
    align-items: center;
}

.j-center {
    flex-direction: column;
    justify-content: center;
}

.ptb-120 {
    padding: 120px 0;
}

.ptb-100 {
    padding: 100px 0;
}

.ptb-90 {
    padding: 90px 0;
}

.ptb-80 {
    padding: 80px 0;
}

.ptb-60 {
    padding: 60px 0;
}

.ptb-50 {
    padding: 50px 0;
}

.ptb-40 {
    padding: 40px 0;
}

.ptb-30 {
    padding: 30px 0;
}

.ptb-20 {
    padding: 20px 0;
}

.bg1 {
    background: #7D003C;
}

.bg2 {
    background: #F2A200;
}

.bg3 {
    background: #222;
}

.bg4 {
    background: #f7f7f7;
}

.relative {
    position: relative;
}

.trans {
    -moz-transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}

/* ============= GLOBAL CSS END ================ */
/*Header*/
.header {
    position: relative;
    z-index: 9;
}

.header-inner {
    height: 80px;
}

.site-logo {
    max-width: 172px;
}

.site-logo a,
.site-logo a img {
    display: block;
    width: 100%;
    margin: 0 auto;
}

/*Nav*/
.header .navbar {
    position: static;
    margin-left: auto;
}

.head-center .menu_head {
    /*display: flex;
    height: 100%;
    justify-content: center;*/
}

.navbar-expand-xl .navbar-nav {
    flex-direction: row;
}

.head-center .menu_head .navbar-nav>li {
    padding: 18px 0;
}

.head-center .menu_head .navbar-nav>li+li {
    margin-left: 26px;
}

.head-center .menu_head .navbar-nav>li.menu-has-children:after {
    display: block;
    content: "";
    background: #F2A200;
    height: 1px;
    opacity: 0;
}

.head-center .menu_head .navbar-nav>li.menu-has-children:hover:after {
    opacity: 1;
}

.navbar-nav .nav-link {
    font-size: 16px;
    line-height: 20px;
    color: #7D003C;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0;
    padding: 12px 2px !important;
    position: relative;
}

.navbar-nav .nav-link:hover {
    color: #F2A200;
}

.navbar-nav .nav-link:after {
    display: block;
    content: "";
    width: 1px;
    height: 2px;
    background: #F2A200;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    opacity: 0;
    -moz-transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}

.navbar-nav .nav-link:hover:after {
    width: 100%;
    opacity: 1;
}

/*header-dropdown*/
.drop-hover-show {
    position: absolute;
    top: 100%;
    width: 100%;
    left: 0;
    z-index: 9999;
    background: #ffffff;
    border-top: 1px solid #E5E5E5;
    min-height: 100px;
    opacity: 0;
    margin: 0;
    transform: rotateX(90deg);
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    transform-origin: top;
}

.drop-hover li:hover .drop-hover-show {
    display: block;
    opacity: 1;
    transform: rotateX(0);
    transform-origin: top;
}

.drop-block {
    padding: 40px 0;
    max-width: 700px;
    margin: auto;
}

.drop-block-inn .left-block {
    padding-right: 60px;
    border-right: 1px solid #E5E5E5;
}

.drop-block-inn .right-block {
    padding-left: 60px;
}

.drop-block .drop-link+.drop-link {
    margin-top: 5px;
}

.navbar-light .drop-box-link .nav-link {
    display: inline-block;
    padding: 5px 0 !important;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}

.navbar-light .drop-box-link .nav-link:hover {
    color: #F2A200;
}

/*Nav Css*/
.head-right {
    margin-left: 30px;
}

.head-phonebox {
    margin-left: 40px;
}

.head-btnbox .btn+.btn {
    margin-left: 10px;
}

.head-btnbox .btn i {
    position: relative;
    top: -2px;
    margin-right: 5px;
}

/*Footer Start*/
.footer {
    padding: 0;
    margin: 0;
    background: #7D003C;
    position: relative;
    overflow: hidden;
}

.footer p {
    font-size: 16px;
    line-height: 22px;
    color: #fff;
    font-weight: 400;
}

.f-links {
    margin: 0;
    padding: 0;
    justify-content: center;
}

.f-links li {
    margin: 0;
    padding: 0;
    font-size: 16px;
    line-height: 22px;
    color: #fff;
    font-weight: 400;
}

.f-links li+li:before {
    display: inline-block;
    content: "|";
    margin: 0 5px;
}

.f-links li a {
    display: inline-block;
    font-size: 16px;
    line-height: 22px;
    color: #fff;
    font-weight: 400;
}

.f-links li a:hover {
    color: #F2A200;
}

.social-link a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 36px;
    height: 36px;
    background: #fff;
    font-size: 24px;
    color: #7D003C;
}

.social-link a+a {
    margin-left: 18px;
}

.social-link a:hover {
    background: #F2A200;
    color: #fff;
}

.footer-bottom p {
    font-size: 18px;
    color: #fff;
}

/*Footer End*/

/*Midlle Section css*/
.middle-section {
    position: relative;
    min-height: 50vh;
    overflow: hidden;
}

img.alignleft {
    float: left;
    margin: 0 20px 20px 0;
}

img.alignright {
    float: right;
    margin: 0 0 20px 20px;
}

img.alignnone {
    margin: 0 0 20px;
}

iframe.alignleft {
    float: left;
    margin: 0 20px 20px 0;
}

iframe.alignright {
    float: right;
    margin: 0 0 20px 20px;
}

iframe.iframe-embed.alignright {
    max-width: 500px;
    height: 315px !important;
}

.card-link {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 3;
}

.card-link a {
    height: 100%;
    width: 100%;
    border-radius: 0;
    opacity: 0;
}

.bg-img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
    background-size: cover !important;
}

.bg-img img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.bg-img:before {
    display: block;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #000;
    opacity: 0.5;
}

.no-img.d-flex {
    align-items: center;
    justify-content: center;
    font-size: 24px;
    line-height: normal;
    color: #999;
    background: #ddd;
}

.btnbox {
    padding: 20px 0 0;
    z-index: 1;
}

.block-title {
    position: relative;
    margin: 0 0 30px;
}

/*Home Banner*/
.home-banner {
    position: relative;
    height: 620px;
}

.banner-caption {
    max-width: 570px;
}

.banner-caption .title-block .h2 {
    font-size: 40px;
    color: #fff;
    margin: 0 0 10px;
}

.banner-caption .title-block p {
    font-size: 20px;
    color: #fff;
}

.banner-caption .btnbox .btn {
    margin: 0 20px 0 0;
}

/*Login Page*/
.login-section {
    position: relative;
    overflow: hidden;
}

.login-section .container {
    max-width: 1000px;
    z-index: 4;
}

.glow-box {
    display: block;
    width: 50%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    opacity: 0.2;
    background: -moz-radial-gradient(center, ellipse cover, rgba(243, 161, 0, 1) 0%, rgba(184, 137, 237, 0) 50%, rgba(255, 255, 255, 0) 100%);
    background: -webkit-radial-gradient(center, ellipse cover, rgba(243, 161, 0, 1) 0%, rgba(184, 137, 237, 0) 50%, rgba(255, 255, 255, 0) 100%);
    background: radial-gradient(ellipse at center, rgba(243, 161, 0, 1) 0%, rgba(184, 137, 237, 0) 50%, rgba(255, 255, 255, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#7113da', endColorstr='#00ffffff', GradientType=1);
}

.circle-top-left {
    display: block;
    width: 152px;
    height: 152px;
    background: #1E2248;
    position: absolute;
    left: -78px;
    top: -80px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    z-index: 1;
}

.circle-bottom-left {
    display: block;
    width: 320px;
    height: 320px;
    background: #F2A200;
    position: absolute;
    left: -158px;
    bottom: -200px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    z-index: 1;
}

.pattern-top-right {
    width: 33px;
    height: 94px;
    background: url('../images/dots_shape.svg') no-repeat center;
    position: absolute;
    right: 30px;
    top: 40px;
    z-index: 1;
}

.pattern-bottom-right {
    width: 100%;
    height: 100%;
    background: url('../images/plant_shape.svg') no-repeat right bottom;
    position: absolute;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(30px);
}

.pattern-top-center {
    width: 72px;
    height: 22px;
    background: url('../images/polygon_small.svg') no-repeat center;
    position: absolute;
    left: 0;
    right: 0;
    top: 40px;
    margin: auto;
    z-index: 1;
}

.login-inner {
    min-height: 100vh;
}

.login-img-wrapper {
    position: relative;
    max-width: 402px;
}

.login-imgbox {
    position: relative;
    z-index: 2;
    background: #fff;
}

.login-img-wrapper .glow-box {
    width: 300px;
    height: 300px;
    left: 100%;
    right: auto;
    top: auto;
    bottom: -60px;
    margin-left: -180px;
}

.logo-block img {
    display: block;
    max-width: 180px;
    margin: 0 auto;
}

.login-imgbox img {
    display: block;
    margin: 0 auto;
}

.login-inner .form-wrapper {
    width: 100%;
    max-width: 410px;
}

.tl {
    font-size: 22px;
    line-height: 24px;
    color: #1E2248;
    font-weight: 600;
}

/*Form Block Style*/
.form-inner {
    flex-wrap: wrap;
    margin: 0 -6px;
}

.input-field {
    padding: 0 6px;
    min-width: 50%;
    max-width: 50%;
    margin: 0 0 18px;
}

.input-field.full-width {
    min-width: 100%;
    max-width: 100%;
}

.input-field .label {
    display: inline-block;
    font-size: 15px;
    line-height: 18px;
    color: #1E2248;
    font-weight: 400;
    margin: 0 0 10px;
}

.input-field input,
.input-field .form-select,
.input-field textarea {
    padding: 2px 18px;
    height: 50px;
    font-size: 15px;
    color: #1E2248;
    background-color: #fff;
    border: 2px solid #c8cde1;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    box-shadow: none !important;
    -moz-transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}

.input-field textarea {
    padding: 15px 18px;
    height: auto;
    /*min-height: 100px;*/
}

.input-field input:hover,
.input-field input:focus,
.input-field .form-select:hover,
.input-field .form-select:focus,
.input-field textarea:hover,
.input-field textarea:focus {
    background-color: #fefaf1;
    border-color: #f3a100;
}

.input-field .form-control:disabled,
.input-field .form-select:disabled {
    background-color: #e9ecef !important;
    border-color: #e9ecef !important;
}

.input-field ::-webkit-input-placeholder {
    color: #1E2248;
    opacity: 0.5;
}

.input-field :-moz-placeholder {
    color: #1E2248;
    opacity: 0.5;
}

/* Firefox 18- */
.input-field ::-moz-placeholder {
    color: #1E2248;
    opacity: 0.5;
}

/* Firefox 19+ */
.input-field :-ms-input-placeholder {
    color: #1E2248;
    opacity: 0.5;
}

.required {
    color: #FF2F2F;
    font-style: normal;
}

.ped-inn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
}

.forgot-link {
    display: inline-block;
    font-size: 15px;
    line-height: 18px;
    color: #F2A200;
    font-weight: 500;
}

.forgot-link:hover {
    color: #1E2248;
    text-decoration: underline;
}

.or-devider {
    font-size: 15px;
    color: #1E2248;
    font-weight: 400;
    text-align: center;
    position: relative;
    height: 1px;
    border-bottom: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 25px 0;
}

.or-devider span {
    padding: 0 10px;
    display: inline-flex;
    text-transform: uppercase;
    background: #fff;
    position: absolute;
}

.link-box {
    margin: 15px 0 0;
}

.link-box p {
    font-size: 15px;
    line-height: 18px;
    color: #1E2248;
    font-weight: 400;
}

.link-box p a {
    color: #F2A200;
    font-weight: 500;
    text-decoration: underline;
}

.link-box p a:hover {
    color: #1E2248;
}

.other-login-block {
    margin: 0 -7px;
}

.other-login-block .btn {
    width: 100%;
    margin: 0 7px;
}

.other-login-block .btn i {
    margin-right: 10px;
}

.facebook_btn {
    text-decoration: underline;
}

.google_btn .fa-google:before {
    display: block;
    background: url('../images/g_icon.svg') no-repeat center;
    width: 17px;
    height: 17px;
    font-size: 0;
    line-height: normal;
}

.option-block {
    flex-wrap: wrap;
}

.option-block .radiobox {
    margin: 0 20px 0 0;
}

.otp-phone-block .btn {
    padding: 14px 10px;
    border-radius: 5px;
    min-width: 96px;
    height: 50px;
    font-size: 15px;
    font-weight: 400;
    margin: 0 12px;
}

.otp-phone-block .otp-field {
    max-width: 86px;
    text-align: center;
}

/*Dashboard Page*/
.main-page {
    min-height: 100vh;
}

.dashboard-header {
    background: #fff;
}

.dash-header-inner {
    height: 70px;
}

.dashboard-logo {
    padding: 0 20px;
    min-width: 262px;
    max-width: 262px;
    border-right: 1px solid #f7f7f7;
}

.dashboard-logo .navbar-brand {
    display: block;
    max-width: 172px;
}

.dash-head-right {
    width: 100%;
    padding: 0 20px;
}

/*Sidebar START*/
.dashboard-sidebar {
    padding: 20px;
    width: 262px;
    background: #fff;
    height: calc(100vh - 70px);
    overflow: auto;
    position: fixed;
    left: 0;
    top: 70px;
    z-index: 91;
}

.dashboard-sidebar .navbar {
    padding: 0;
}

.sidebar_head-center {
    width: 100%;
}

.sidebar-nav {
    margin: 0;
    padding: 0;
}

.sidebar-nav li {
    margin: 0;
    padding: 0;
}

.sidebar-nav li+li {
    margin-top: 7px;
}

.sidebar-nav li .nav-link {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 20px;
    color: #1E2248;
    font-weight: 500;
    letter-spacing: 0;
    border: 1px solid transparent;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
}

.sidebar-nav .iconbox {
    display: flex;
    min-width: 22px;
    max-width: 22px;
    margin-right: 12px;
}

.sidebar-nav li .nav-link:hover,
.sidebar-nav li.active .nav-link {
    background: #f8f2f5;
    border-color: #f2e6ec;
}

/*Sidebar END*/
.main-section {
    padding-left: 262px;
}

.main-inner {
    padding: 28px;
    height: calc(100vh - 70px);
    overflow: auto;
}

.head-title-box h4,
.dash-title-block h4 {
    font-size: 20px;
    line-height: 24px;
    color: #1E2248;
    font-weight: 500;
}

.head-title-box h4 a,
.dash-title-block h4 a {
    color: #1E2248;
}

.dash-head-iconbox {
    margin-left: 15px;
}

.head-icon-link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    background: #f7f7f7;
    font-size: 20px;
    color: #222;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.head-icon-link+.head-icon-link {
    margin-left: 15px;
}

.head-icon-link img {
    display: block;
    max-width: 25px;
}

.head-icon-link:hover {
    background: #FFEDF6;
    color: #7D003C;
}

.cart-count {
    display: block;
    width: 20px;
    height: 20px;
    background: #fff;
    overflow: hidden;
    border-radius: 50%;
    position: absolute;
    right: -5px;
    top: -6px;
    font-size: 10px;
    line-height: 20px;
    color: #7d003c;
    font-weight: 600;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}

.whatsapp_icon img {
    position: relative;
    top: -1px;
    right: -1px;
}

.head-profile {
    margin-left: 15px;
}

.profile-link.dropdown-toggle {
    height: 70px;
    background: none !important;
    border: 0;
    padding: 0;
    box-shadow: none;
    font-size: 16px;
    line-height: 20px;
    color: #222;
    font-weight: 500;
    min-width: inherit;
}

.profile-link .head-icon-link {
    padding: 4px;
    background: #fff;
    border: 1px solid #f7f7f7;
    margin-right: 10px;
}

.profile-link .head-icon-link img {
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.profile-name {
    font-size: 16px;
    line-height: 20px;
    color: #222;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 110px;
}

.head-profile .dropdown-menu {
    padding: 10px 0;
    border: 0;
    background: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.head-profile .dropdown-menu:before {
    display: block;
    content: "";
    position: absolute;
    bottom: 100%;
    left: 6px;
    margin: auto;
    height: 0;
    width: 0;
    border: solid transparent;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: #f7f7f7;
    border-width: 15px;
}

.head-profile .dropdown-item {
    padding: 5px 15px;
    font-size: 14px;
    line-height: 20px;
    color: #222;
    font-weight: 400;
}

.dash-title-block {
    border-bottom: 1px solid #ddd;
    padding: 0 0 10px;
    margin: 0 0 20px;
}

.box-title {
    margin: 0 0 20px;
}

.box-title.row {
    margin-left: calc(-.5 * var(--bs-gutter-x));
    margin-right: calc(-.5 * var(--bs-gutter-x));
}

.white_box {
    padding: 25px 30px 30px;
    background: #fff;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.result-boxes-slider {
    margin: 0 -10px;
}

.card-result-dash {
    padding: 0 10px;
}

.card-result-dash .imgbox img {
    display: block;
    width: 100%;
    height: auto;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.dash-result-block+.dashboard-blocks {
    margin-top: 30px;
}

.dash-boxes-wrapper {
    margin: 0 -15px;
    flex-wrap: wrap;
}

.dash-boxes-wrapper .items {
    padding: 0 15px;
    margin: 0 0 30px;
    min-width: 50%;
    max-width: 50%;
}

.card-boxes {
    padding: 30px 20px;
    height: 100%;
    background: #fff;
    text-align: center;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.card-boxes .iconbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    margin: 0 auto 20px;
    background: #f8f2f6;
    border: 1px solid #ebdae2;
    border-radius: 20px;
    -moz-transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}

.text-link {
    font-size: 15px;
    line-height: 18px;
    color: #7D003C;
    justify-content: center;
}

.text-link i {
    margin-left: 10px;
    font-size: 20px;
}

.card-boxes:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
}

.card-boxes:hover h4 {
    color: #7D003C;
}

.card-boxes:hover .iconbox {
    border-color: #7D003C;
}

/*Dashboard Review Block*/
.testimonial-wrapper {
    padding: 34px 30px 30px;
    min-height: calc(100% - 30px);
}

.review-list .sub-items+.sub-items {
    margin-top: 28px;
}

.dash-card-review .imgbox {
    min-width: 50px;
    max-width: 50px;
    height: 50px;
    overflow: hidden;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    margin-right: 10px;
}

.dash-card-review .imgbox img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.dash-card-review .review-name {
    display: block;
    font-size: 14px;
    line-height: 18px;
    color: #222;
    font-weight: 500;
}

.dash-card-review p {
    font-size: 12px;
    line-height: 18px;
    color: #777;
}

.review-star-box {
    font-size: 12px;
    line-height: 14px;
    color: #FFAA00;
    letter-spacing: 0.05em;
    margin: 2px 0;
}

/*Modal Style*/
.modal-content {
    background: #fff;
    border: 0;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
}

.modal-header {
    padding: 20px 40px;
}

.modal-body {
    padding: 40px;
}

.modal-title {
    font-weight: 500;
}

.box-title h3 {
    color: #1E2248;
}

.delete_icon {
    position: absolute;
    right: -8px;
    top: -8px;
    background: #fff;
    width: 24px;
    height: 24px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: red;
    cursor: pointer;
}

.white_box.prifile-about {
    padding: 40px 30px;
}

.prifile-about .imgbox {
    padding: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0 auto 15px;
    width: 120px;
    height: 120px;
    border: 1px solid #7D003C;
    overflow: hidden;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.prifile-about .imgbox img {
    display: block;
    width: 100px;
    height: 100px;
    object-fit: cover;
    object-position: top;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.prifile-about h4 {
    margin: 0 0 10px;
}

.prifile-about p {
    font-size: 13px;
    line-height: 24px;
    color: #999;
}

.prifile-about .social-circle {
    margin-top: 20px;
}

.social-circle {
    justify-content: center;
}

.social-circle a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 35px;
    max-width: 35px;
    height: 35px;
    background: #FFEDF6;
    color: #7D003C;
    font-size: 18px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.social-circle a+a {
    margin-left: 10px;
}

.social-circle a:hover {
    background: #7D003C;
    color: #fff;
}

.upload-img {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.5);
    font-size: 10px;
    line-height: 14px;
    color: #fff;
    cursor: pointer;
}

.upload-img i {
    display: block;
    font-size: 20px;
    margin: 0 0 5px;
}

.upload-img:hover {
    background: rgba(0, 0, 0, 0.7);
}

.upload-img input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.profile-update-imgbox {
    width: 100%;
    height: 100%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    overflow: hidden;
}

/*Order Medicines*/
.breadcrumb-list li {
    margin: 0;
    padding: 0;
    position: relative;
    font-size: 15px;
    line-height: 18px;
    color: #666;
    font-weight: 400;
}

.breadcrumb-list li+li:before {
    display: inline-block;
    content: "\f105";
    font-family: 'Line Awesome Free';
    font-weight: 900;
    font-size: 12px;
    margin: 0 3px;
}

.breadcrumb-list li a {
    color: #999;
}

.breadcrumb-list li a:hover {
    color: #7D003C;
}

.product-list .items {
    min-width: 190px;
    max-width: 190px;
    margin: 0 0 30px;
}

.card-prod {
    flex-direction: column;
    position: relative;
    text-align: center;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.pro-imgbox {
    width: 100%;
    max-width: 160px;
    height: 152px;
    background: #f9f9f9;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto 15px;
}

.pro-imgbox img {
    display: block;
    margin: 0 auto;
    object-fit: contain;
}

.card-prod h4 {
    font-size: 16px;
    line-height: 18px;
    color: #1E2248;
    font-weight: 600;
    margin: 0 0 10px;
}

.card-prod p {
    font-size: 14px;
    line-height: 18px;
    color: #1E2248;
    font-weight: 400;
    margin: 0;
}

.card-prod .prod-price {
    display: block;
    font-size: 16px;
    line-height: 18px;
    color: #1E2248;
    font-weight: 600;
    margin: 10px 0 0;
}

.prod-btnbox {
    padding: 10px 0 0;
}

.btn.addtocard_btn {
    padding: 8px 5px;
    height: 42px;
    width: 100%;
    background: #1E2248;
    border-color: #1E2248;
    color: #fff;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    letter-spacing: 0;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.btn.addtocard_btn i {
    margin-left: 7px;
    font-size: 24px;
}

.btn.addtocard_btn:hover {
    background: #7D003C;
    border-color: #7D003C;
    color: #fff;
}

/*provide Page*/
.provide-list {
    counter-reset: li-counter;
}

.provide-list .items {
    padding-right: 25px;
    margin: 0 0 20px 0;
}

.card-provider {
    position: relative;
    border: 2px solid transparent;
    height: 100%;
}

.number-circle {
    min-width: 50px;
    max-width: 50px;
    height: 50px;
    background: #F8F2F5;
    border: 1px solid rgba(125, 0, 60, 0.1);
    margin-right: 20px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    font-size: 20px;
    line-height: 24px;
    color: #7D003C;
    font-weight: 500;
}

.number-circle:before {
    display: inline-block;
    content: counter(li-counter, decimal);
    counter-increment: li-counter;
}

.card-provider h4 {
    color: #222222;
    margin: 0 0 10px;
}

.card-provider p {
    font-size: 15px;
    line-height: 24px;
    color: #555;
}

.check-circle {
    position: absolute;
    right: -15px;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 30px;
    height: 30px;
    font-size: 18px;
    color: #fff;
    background: #fff;
    border: 2px solid rgba(125, 0, 60, 0.3);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.check-circle:before {
    display: block;
    content: "\f00c";
    font-family: 'Line Awesome Free';
    font-weight: 900;
    opacity: 0;
}

.card-provider:hover {
    border-color: #7d003c;
}

.card-provider:hover .check-circle {
    background: #7d003c;
    border-color: #7d003c;
}

.card-provider:hover .check-circle:before {
    opacity: 1;
}

/*Thank You page*/
.thankyou-innbox {
    width: 100%;
    max-width: 800px;
    margin: 40px auto !important;
    background: url('../images/thanks_shapes.svg') no-repeat center top;
}

.thankyou-iconbox {
    max-width: 80px;
    margin: 0 auto 20px;
}

.thankyou-iconbox img {
    display: block;
    margin: 0 auto;
    width: 100%;
}

.table-block .table {
    width: 100%;
    word-break: break-all;
}

.table-block .table td {
    color: #555;
}

.table-block .table td:first-child {
    width: 300px;
    color: #222;
}

/*calendar_style*/
.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
    margin: 0;
}

.fc-daygrid-event-harness .fc-daygrid-event {
    display: block;
    padding: 10px;
    background: #fef0f7;
    border-top: 2px solid #7D003C;
    font-size: 14px;
    color: #222;
    margin: 0 !important;
    overflow: hidden;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    border-radius: 7px;
}

.fc-daygrid-event-harness .fc-daygrid-event img {
    max-width: 25px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.calendar_style .fc .fc-daygrid-day.fc-day-today {
    background-color: #f7f7f7;
}

.calendar_style .fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
    background: #7d003c;
    color: #fff;
}

.calendar_style .fc .fc-toolbar-title {
    font-size: 18px;
    line-height: 24px;
    color: #222;
    font-weight: 500;
}

.calendar_style .fc .fc-button-primary {
    background: #1E2248;
    border-color: #1E2248;
    border-radius: 30px;
    text-transform: capitalize;
}

.calendar_style .fc-theme-standard .fc-scrollgrid,
.calendar_style .fc-theme-standard td,
.calendar_style .fc-theme-standard th {
    border: 0;
    background: none;
    font-size: 14px;
    line-height: 16px;
    color: #222;
    font-weight: 500;
}

.calendar_style .fc-theme-standard .fc-col-header .fc-col-header-cell-cushion {
    font-size: 16px;
    line-height: 20px;
    color: #222;
    font-weight: 500;
}

.calendar_style .fc .fc-daygrid-day-number {
    padding: 0;
    color: #222;
    font-weight: 600;
    min-width: 26px;
    line-height: 26px;
    text-align: center;
    border-radius: 50%;
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 9;
}

.calendar_style .fc .fc-scrollgrid table {
    height: auto !important;
}

.calendar_style .fc-theme-standard .fc-col-header th,
.calendar_style .fc-scrollgrid-sync-table tbody td {
    height: auto;
    min-height: 80px;
}

.calendar_style .fc-theme-standard .fc-col-header th:first-child,
.calendar_style .fc-scrollgrid-sync-table tbody td:first-child {
    padding-left: 1px;
}

.calendar_style thead .fc-col-header-cell .fc-scrollgrid-sync-inner {
    padding: 20px 0;
    background: #f8f8f8;
    border: 1px solid #e0e0e0;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    margin: 0 0 -1px -1px;
}

.calendar_style .fc .fc-daygrid-day-frame {
    padding: 0;
    border: 1px solid #e0e0e0;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    height: auto;
    min-height: 80px;
    margin: 0 0 -1px -1px;
}

/*Modal Booking*/
.mw_200 {
    min-width: 200px;
}

.appoint-booking-body .btnbox {
    justify-content: center;
    flex-wrap: wrap;
}

.btn-items {
    padding: 5px;
}

.option-block .radiobox.time-radio {
    padding: 5px 10px;
    background: #FE9A3F;
    border-radius: 30px;
    font-size: 12px;
    color: #fff;
    margin: 0 10px 10px 0;
}

.option-block .radiobox.time-radio .checkmark {
    display: none;
}

.tooltip-box {
    margin: 10px 0 0;
}

.tooltip-text {
    display: inline-flex;
    padding: 4px 0;
    font-size: 13px;
    line-height: 18px;
    color: #222;
    border-bottom: 1px dashed #222;
    position: relative;
    z-index: 3;
}

.tooltip-hover {
    position: absolute;
    bottom: 100%;
    left: 50%;
    opacity: 0;
    z-index: -1;
    background: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    min-width: 440px;
    transform: translate(-50%, 0);
    border-radius: 10px;
    padding: 20px;
    text-align: left;
    -moz-transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}

.tooltip-hover p {
    font-size: 15px;
    line-height: 22px;
    margin: 0;
}

.tooltip-text:hover+.tooltip-hover {
    opacity: 1;
    z-index: 2;
}

#modalPayment {
    max-width: 600px;
}

.appoint-booking-payment p {
    color: #1E2248;
}

.payment-radio .option-block .radiobox {
    padding-left: 40px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: #1E2248;
}

.payment-radio .option-block .radiobox .checkmark {
    width: 24px;
    height: 24px;
    border: 1px solid #429CFF;
}

.payment-radio .radiobox .checkmark:after {
    background: #429CFF;
    left: 4px;
    right: 4px;
    top: 4px;
    bottom: 4px;
}

.payment-radio+.payment-radio {
    border-top: 2px solid #e3e6f0;
    padding-top: 15px;
}

.razorpay-option-block {
    margin-top: 10px;
}

.razorpay-icon {
    display: inline-block;
    margin-right: 10px;
}

.razorpay-icon img {
    display: block;
}

.description-razorpay {
    padding: 15px 20px;
    background: #D9D9D9;
    position: relative;
    margin-top: 40px;
}

.description-razorpay:after {
    display: block;
    content: "";
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    bottom: 100%;
    left: 30px;
    pointer-events: none;
    border-color: rgba(217, 217, 217, 0);
    border-bottom-color: #D9D9D9;
    border-width: 18px;
}

.description-razorpay small {
    display: inline-block;
    font-size: 14px;
    color: #1E2248;
    font-weight: 500;
}

.file-upload-box {
    position: relative;
}

.drag-drop-box {
    padding: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    border: 2px dashed #D9D9D9;
    min-height: 130px;
    font-size: 16px;
    line-height: 18px;
    color: #C8CDE1;
    font-weight: 400;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.drag-drop-box i {
    display: block;
    font-size: 30px;
    line-height: 30px;
    margin: 0 0 10px;
}

.file-upload-box input[type="file"] {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: auto;
    border: 0;
    background: none;
    cursor: pointer;
    z-index: 2;
    opacity: 0;
}

.file-uploaded .drag-drop-box {
    display: none !important;
}

.file-upload-box:not(.file-uploaded) .myphoto-imgbox {
    display: none;
}

.text-20 {
    font-size: 20px;
}

.uploaded-files {
    display: none;
    align-items: center;
    padding: 10px;
    border: 1px solid #c8cde1;
    border-radius: 10px;
    position: relative;
}

.uploaded-files i {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    margin-right: 10px;
    min-width: 40px;
    height: 40px;
    background: #eee;
    border: 1px solid #c8cde1;
    border-radius: 5px;
}

.file-uploaded .uploaded-files {
    display: flex;
    display: -webkit-flex;
}

.file-uploaded+.allow-file-label {
    display: none;
}

/*Cart Page*/
.cart-list-wrapper {
    padding: 40px;
}

.cart-items+.cart-items {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #e0e0e0;
}

.cart-imgbox {
    min-width: 200px;
    max-width: 200px;
    margin-right: 30px;
}

.cart-imgbox img {
    display: block;
}

.cart-info-textbox {
    width: 100%;
}

.cart-info-textbox .sub-item {
    align-items: center;
}

.cart-info-textbox .sub-item+.sub-item {
    margin-top: 15px;
}

.cart-label {
    font-size: 15px;
    line-height: 20px;
    color: #666;
    font-weight: 400;
    min-width: 120px;
}

.cart-value {
    margin-left: auto;
    font-size: 15px;
    line-height: 20px;
    color: #222;
    font-weight: 400;
    text-align: right;
}

.cart-inputbox {
    margin-left: auto;
    border: 1px solid #ddd;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.cart_number,
.cart-inputbox button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    color: #1E2248;
    font-weight: 600;
    width: 40px;
    height: 40px;
}

.cart-total-wrapper {
    padding: 40px;
}

.cart-total-list .cart-value {
    white-space: nowrap;
}

.grand-total-item .cart-label,
.grand-total-item .cart-value {
    font-size: 16px;
    color: #222;
}

.cart-total-list {
    padding-top: 20px;
    border-top: 1px solid #e0e0e0;
}

.cart-total-list .sub-item+.sub-item {
    margin-top: 20px;
}

.grand-total-item {
    padding-top: 20px;
    border-top: 1px solid #e0e0e0;
}

/*Checkout Page*/
.right-block .white_box+.white_box {
    margin-top: 30px;
}

.checkout-cart-list .cart-items {
    flex-wrap: wrap;
}

.checkout-cart-list .cart-imgbox {
    min-width: 110px;
    max-width: 110px;
    margin: 0 auto 15px;
}

.checkout-cart-list .cart_number,
.checkout-cart-list .cart-inputbox button {
    width: 34px;
    height: 34px;
}

.block-radiobox {
    display: block;
    position: relative;
}

.block-radiobox input {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;
    cursor: pointer;
}

.card-address {
    padding: 22px 30px;
    position: relative;
    border: 2px solid #dde1f1;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.card-address h4 {
    font-size: 18px;
    line-height: 22px;
    color: #222222;
    margin: 0 0 10px;
}

.card-address p {
    font-size: 15px;
    line-height: 24px;
    color: #555;
}

.block-radiobox input:checked~.card-address {
    background: #fefaf2;
    border-color: #F2A200;
}

.style-none.accordion {
    border: 0;
    background: none;
}

.style-none .accordion-item {
    border: 0;
}

.style-none .accordion-header {
    display: flex;
}

.style-none .accordion-button {
    padding: 0;
    border: 0;
    box-shadow: none;
    background: none;
    width: auto;
}

.style-none .accordion-button::after {
    display: none;
}

.style-none .accordion-body {
    padding: 0;
}

.style-none .accordion-button[aria-expanded="true"] {
    display: none;
}

/*My Account Page*/
.account-table-block {
    overflow: auto;
}

.small-btn {
    padding: 4px 14px;
    font-size: 14px;
    letter-spacing: 0;
    min-width: auto;
    border-color: transparent;
    background: #e5f0ff;
    color: #1E2248;
}

.red-btn {
    background: #7D003C;
    border-color: #7D003C;
    color: #fff;
}

.y-btn {
    background: #F2A200;
    border-color: #F2A200;
    color: #fff;
}

.btn.ongoing {
    background: #e5f0ff;
    border-color: #e5f0ff;
    color: #0066FF;
    pointer-events: none;
}

.btn.due,
.btn.canceled {
    background: #ffe9e5;
    border-color: #ffe9e5;
    color: #FF1F00;
    pointer-events: none;
}

.btn.postponed {
    background: #fff3e5;
    border-color: #fff3e5;
    color: #FF8A00;
    pointer-events: none;
}

.btn.completed {
    background: #e9f9e5;
    border-color: #e9f9e5;
    color: #28C600;
    pointer-events: none;
}

.download_icon {
    display: inline-block;
    min-width: 15px;
    height: 15px;
    background: url('../images/download_icon.svg') no-repeat center 0;
    margin-right: 3px;
    position: relative;
    top: -1px;
}

.pay_icon {
    display: inline-block;
    min-width: 14px;
    height: 10px;
    background: url('../images/pay_icon.svg') no-repeat center 0;
    margin-right: 5px;
}

.account-table-block table {
    border: 1px solid #f1f1f1;
}

.account-table-block table th,
.account-table-block table td {
    border-left: 0;
    font-size: 14px;
    line-height: 18px;
}

.account-table-block table th {
    font-size: 16px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.consultant-prof .imgbox {
    display: inline-block;
    min-width: 30px;
    max-width: 30px;
    margin-right: 10px;
}

.consultant-prof .imgbox img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.result-text p {
    font-size: 15px;
    color: #666;
}

/*Pagination*/
.pagination .page-item {
    margin-left: 5px;
}

.pagination .page-item .page-link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0;
    border: 0;
    font-size: 16px;
    color: #666;
    line-height: normal;
    background: #f5f5f5;
    min-width: 35px;
    max-width: 35px;
    height: 35px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.pagination .page-item.active .page-link {
    background: #7d003c;
    color: #fff;
}

.pagination .page-item a.page-link:hover {
    background: #ffedf6;
    color: #7d003c;
}

.pagination .page-item .page-link span {
    font-size: 24px;
    padding-bottom: 2px;
}

.filter-field-box .label {
    white-space: nowrap;
    font-size: 16px;
    color: #666;
    font-weight: 400;
    margin-right: 10px;
}

.filter-field-box .form-select {
    min-width: 170px;
}

/*My Photos Page*/
.photo-items {
    margin-bottom: 30px;
}

.label-other {
    display: inline-block;
    font-size: 18px;
    line-height: 20px;
    color: #1E2248;
    font-weight: 500;
    margin: 0 0 15px;
}

.myphoto-imgbox {
    max-width: 250px;
}

.myphoto-imgbox img {
    display: block;
    border: 1px solid #7D003C;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

/*EMI Options Page*/
.user-sub-items {
    padding: 20px 0;
    position: relative;
    font-size: 16px;
    line-height: 20px;
    color: #555;
    font-weight: 400;
    word-break: break-word;
}

.user-sub-items+.user-sub-items {
    border-top: 1px solid #f7f7f7;
}

.user-label {
    color: #222;
    min-width: 40%;
}

.white_box.chat-wrapper {
    padding: 0;
    overflow: hidden;
}

.chat-wrapper .box-title {
    padding: 10px 30px;
    height: 60px;
}

.chat-wrap-bottom {
    padding: 0 30px;
    height: 70px;
}

.chat-btnbox .chat-input {
    padding: 2px 18px 4px 18px;
    width: 100%;
    height: 44px;
    background: #f7f7f7;
    border: 0;
    font-size: 15px;
    line-height: normal;
    color: #222;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    border-radius: 40px;
    box-shadow: none;
}

.chat-btnbox ::-webkit-input-placeholder {
    color: #C8CDE1;
    opacity: 1;
}

.chat-btnbox :-moz-placeholder {
    color: #C8CDE1;
    opacity: 1;
}

/* Firefox 18- */
.chat-btnbox ::-moz-placeholder {
    color: #C8CDE1;
    opacity: 1;
}

/* Firefox 19+ */
.chat-btnbox :-ms-input-placeholder {
    color: #C8CDE1;
    opacity: 1;
}

.chat-btnbox .btn {
    display: inline-flex;
    min-width: 44px;
    max-width: 44px;
    height: 44px;
    padding: 0;
    background: #f7f7f7;
    border: 0;
    margin-left: 5px;
    color: #b0b0b0;
}

.chat-btnbox .btn:hover {
    box-shadow: none;
}

.chat-btnbox .chat-btn {
    background: #7d003c;
    color: #fff;
}

.chat-btnbox .chat-btn i {
    font-size: 24px;
    text-indent: 5px;
}

.chat-btnbox .btn:hover {
    background: #7d003c;
    color: #fff;
}

.chat-btnbox .chat-upload-btn i {
    font-size: 24px;
    text-indent: 2px;
}

.chat-btnbox .chat-upload-btn:hover {
    background: #f5f5f5;
    color: #7d003c;
}

.chat-wrap-middle {
    padding: 30px 30px;
    position: relative;
    height: calc(100vh - 256px);
    overflow: auto;
    background: linear-gradient(to bottom, rgba(250, 250, 250, 1) 1%, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 1) 80%, rgba(250, 250, 250, 1) 100%);
    align-items: flex-end;
}

.typing-text {
    display: block;
    font-size: 12px;
    line-height: 14px;
    color: #C8CDE1;
    font-weight: 400;
    padding: 0 0 10px;
}

.chat-imgbox {
    padding: 2px;
    display: inline-flex;
    min-width: 36px;
    max-width: 36px;
    height: 36px;
    border: 1px solid #E0E0E0;
    overflow: hidden;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    margin-right: 10px;
}

.chat-text {
    padding: 15px 16px;
    display: inline-flex;
    background: #f7f7f7;
    position: relative;
    -webkit-border-radius: 10px;
    -webkit-border-top-left-radius: 0;
    -moz-border-radius: 10px;
    -moz-border-radius-topleft: 0;
    border-radius: 10px;
    border-top-left-radius: 0;
    max-width: 90%;
    font-size: 14px;
    line-height: 20px;
    color: #222222;
    font-weight: 400;
}

.chat-text-list {
    width: 100%;
}

.chat-text-list .chat-text+.chat-text {
    margin-top: 3px;
}

.chat-text-list .chat-text:first-child:before {
    display: block;
    content: "";
    width: 0;
    border-style: solid;
    border-width: 12px 0 0 10px;
    border-color: #f7f7f7 transparent transparent transparent;
    position: absolute;
    top: 0;
    right: 100%;
}

.msg-items {
    position: relative;
}

.msg-time {
    font-size: 10px;
    line-height: 14px;
    color: #555;
    position: absolute;
    left: 46px;
    bottom: 100%;
}

.msg-items+.msg-items {
    margin-top: 30px;
}


.my-msg-items .chat-text-list {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.my-msg-items .chat-text {
    background: #7d003c;
    color: #fff;
    -webkit-border-radius: 10px;
    -webkit-border-bottom-right-radius: 0;
    -moz-border-radius: 10px;
    -moz-border-radius-bottomright: 0;
    border-radius: 10px;
    border-bottom-right-radius: 0;
}

.my-msg-items .msg-time {
    left: auto;
    right: 5px;
}

.my-msg-items .chat-text-list .chat-text:first-child:before {
    display: none;
}

.my-msg-items .chat-text-list .chat-text:last-child:before {
    display: block;
    content: "";
    width: 0;
    border-style: solid;
    border-width: 12px 10px 10px 0px;
    border-color: transparent transparent #7d003c transparent;
    position: absolute;
    bottom: 0;
    left: 100%;
}

.chat-btn-mobi {
    display: inline-flex;
    min-width: 44px;
    max-width: 44px;
    height: 44px;
    padding: 0;
    background: #7d003c;
    border: 0;
    color: #fff;
    position: fixed;
    right: 10px;
    bottom: 10px;
}

.chat-btn-mobi:hover {
    box-shadow: none;
}

.chat-btn-mobi i {
    font-size: 24px;
}

.modal_chat .modal-dialog-centered {
    align-items: flex-end;
}

.modal_chat .chat-wrap-middle {
    padding: 20px;
    height: calc(100vh - 210px);
}
.chat-form .error-message {
    padding: 0 30px 10px; font-size: 12px; line-height: normal; color: red;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.autocomplete-dropdown-container {
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

.autocomplete-dropdown-container ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.autocomplete-dropdown-container ul li {
    font-size: 14px;
    padding: 5px 15px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
}

.autocomplete-dropdown-container ul li:hover {
    background: #eee;
}

.btnbox.option-btn .btn {
    padding: 14px 15px;
    min-width: 180px;
    font-size: 15px;
}

.upload-imgs {
    width: 100%;
    margin: 0 -5px;
}

.upload-imgs .items {
    padding: 0 5px;
}

.upload-imgs .imgbox {
    position: relative;
    padding: 0 0 30%;
    min-width: 50%;
    max-width: 50%;
    margin: 5px;
}

.upload-imgs .imgbox img {
    width: 100%;
    display: block;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
}

.emi-form-block .uploaded-files {
    display: flex;
    align-items: center;
}

.file-uploaded .upload-pre {
    display: none;
}

.upload-imgs .imgbox i {
    width: 25px;
    height: 25px;
    background: #7d013c;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    position: absolute;
    z-index: 1;
    right: -10px;
    top: -10px;
}

.uploaded-files i.fa-trash {
    border-radius: 50%;
    border: none;
    background: #ffedf5;
    color: #7d013c;
    font-size: 20px;
    min-width: 35px;
    max-width: 35px;
    height: 35px;
    position: absolute;
    right: -17px;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
}
.file-link i{
	font-size: 17px;
}
.thank-you-modal .modal-header button.btn-close {
    position: absolute;
    top: 20px;
    right: 20px;
}

.btn.reschedule {
    pointer-events: none;
}


/*==========================================
===============:: MEDIA CSS ::==============
============================================*/
@media screen and (max-width:1599px) {
    .container {
        max-width: 1180px;
    }

    .cart-list-wrapper,
    .cart-total-wrapper {
        padding: 30px;
    }
}

@media screen and (max-width:1365px) {
    .container {
        max-width: 1100px;
    }

    .login-section .container {
        max-width: 940px;
    }

    .cart-imgbox {
        min-width: 140px;
        max-width: 140px;
        margin-right: 20px;
    }

    .cart-info-textbox .sub-item+.sub-item {
        margin-top: 10px;
    }

}

@media screen and (max-width:1280px) {
    .head-center .menu_head .navbar-nav>li+li {
        margin-left: 15px;
    }

}

/* iPad 1024px View */
@media screen and (max-width:1199px) {
    .container {
        width: 100%;
        max-width: 100%;
        padding: 0 20px;
    }

    .btnbox {
        padding: 10px 0 0;
    }

    .ptb-60 {
        padding: 40px 0;
    }

    .ptb-100,
    .ptb-80,
    .ptb-90 {
        padding: 60px 0;
    }

    h2,
    .h2 {
        font-size: 24px;
        line-height: 1.2;
    }

    h3 {
        font-size: 20px;
        line-height: 1.2;
    }

    h4 {
        font-size: 18px;
        line-height: 24px;
    }

    h5 {
        font-size: 16px;
        line-height: 22px;
    }

    p {
        font-size: 16px;
        line-height: 24px;
    }

    ul li,
    ol li {
        font-size: 16px;
        line-height: 24px;
    }

    .block-title {
        margin: 0 0 30px;
    }

    .btn-style2 {
        padding: 15px 40px;
    }

    /*Header*/
    body .navbar-toggler {
        padding: 0;
        border: 0;
        width: 40px;
        height: 40px;
        box-shadow: none;
    }

    .navbar-toggler .navbar-toggler-icon {
        color: #7D003C;
    }

    body .offcanvas {
        max-width: 260px;
    }

    .mobile-header {
        height: 70px;
        padding: 0 10px;
        border-bottom: 1px solid #ddd;
    }

    .offcanvas-body {
        padding: 10px;
    }

    .sidebar-nav li+li {
        margin-top: 0;
    }

    .sidebar-nav li .nav-link {
        font-size: 14px;
    }

    .offcanvas-header .btn-close {
        position: relative;
        right: 10px;
    }

    .head-center .menu_head .navbar-nav>li {
        padding: 0;
    }

    .head-center .menu_head .navbar-nav>li+li {
        margin-left: 0;
    }

    .navbar-nav .nav-link {
        padding: 10px 20px !important;
    }

    .navbar-nav .nav-link:after {
        display: none;
    }

    .head-right {
        margin-left: 0;
        padding: 0 15px;
    }

    .head-right .head-btnbox {
        display: flex;
        align-items: center;
    }

    .head-right .head-btnbox .btn {
        min-width: 100px;
    }

    .footer-inner-block {
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
    }

    .footer-inner-block .items {
        min-width: 100%;
        padding: 5px 0;
    }

    .f_col_1 {
        order: 3;
    }

    .f_col_2 {
        order: 2;
    }

    .f_col_3 {
        order: 1;
    }

    .social-link {
        justify-content: center;
        margin-bottom: 10px;
    }

    .social-link a+a {
        margin-left: 10px;
    }

    .circle-bottom-left {
        width: 180px;
        height: 180px;
        left: -70px;
        bottom: -90px;
    }

    .pattern-top-center {
        top: 20px;
    }

    .pattern-top-right {
        right: 10px;
        top: 10px;
    }

    .pattern-bottom-right {
        background-size: 90px auto;
    }

    /*dashboard Page*/
    .dashboard-logo {
        padding: 0 10px;
        min-width: 180px;
        max-width: 180px;
    }

    .dashboard-logo .navbar-brand {
        max-width: 150px;
    }

    .dashboard-sidebar .navbar-toggler {
        position: fixed;
        left: 190px;
        top: 16px;
    }

    .dashboard-sidebar {
        padding: 0;
        width: auto;
    }

    .head-title-box {
        display: none;
    }

    .profile-link .head-icon-link {
        margin-right: 5px;
    }

    .profile-name {
        font-size: 14px;
    }

    .head-profile,
    .dash-head-iconbox,
    .head-icon-link+.head-icon-link {
        margin-left: 10px;
    }

    .main-section {
        padding-left: 0;
    }

    .main-inner {
        padding: 20px;
    }

    .dash-head-right-btnbox {
        display: none;
    }

    .dash-title-block {
        display: block;
    }

    .product-list .items {
        min-width: 20%;
        max-width: 20%;
    }

    .cart_number,
    .cart-inputbox button {
        width: 34px;
        height: 34px;
    }

    .right-block .white_box+.white_box {
        margin-top: 20px;
    }

    .account-table-block table {
        min-width: 840px;
    }

    .chat-wrapper .box-title {
        padding: 10px 20px;
    }

    .chat-wrap-middle {
        padding: 20px;
        height: calc(100vh - 310px);
    }

    .user-sub-items {
        padding: 12px 0;
        font-size: 15px;
        line-height: 18px;
    }

    .chat-wrap-bottom {
        padding: 0 10px;
    }

}

/* iPad 768px view*/
@media screen and (max-width:991px) {
    .ptb-120 {
        padding: 80px 0;
    }

    .ptb-100,
    .ptb-80 {
        padding: 60px 0;
    }

    .button:before,
    .btn:before {
        display: none;
    }

    /*Header and banner*/
    .login-inner .left-block {
        display: none;
    }

    .show_mobi_view {
        display: block;
    }

    .dash-boxes-wrapper {
        margin: 0 -10px;
    }

    .dash-boxes-wrapper .items {
        padding: 0 10px;
        margin: 0 0 20px;
    }

    .card-boxes h4 {
        font-size: 16px;
        line-height: 22px;
    }

    .white_box {
        padding: 18px 20px 20px;
    }

    .box-title {
        margin: 0 0 15px;
    }

    .dash-result-block+.dashboard-blocks {
        margin-top: 20px;
    }

    .modal .input-field {
        min-width: 100%;
        max-width: 100%;
    }

    .product-list .items {
        min-width: 25%;
        max-width: 25%;
    }

    .checkout-blocks .right-block {
        margin-top: 20px;
    }

    .pagination .page-item .page-link {
        font-size: 12px;
        min-width: 24px;
        max-width: 24px;
        height: 24px;
    }

    .pagination .page-item .page-link span {
        font-size: 20px;
    }

    .result-text p {
        font-size: 13px;
    }

    .filter-field-box .label {
        font-size: 13px;
    }

    .filter-field-box .form-select {
        min-width: 160px;
        height: 34px;
        font-size: 13px;
    }

}

/* iPhone-6 plus 736 (Landscape) view */
@media screen and (max-width:767px) {
    body {
        -webkit-text-size-adjust: none;
    }

    .ptb-120,
    .ptb-100,
    .ptb-80,
    .ptb-90 {
        padding: 50px 0;
    }

    .ptb-60,
    .ptb-50 {
        padding: 40px 0;
    }

    .main-inner {
        padding: 20px 15px;
    }

    /*Header and banner*/
    .circle-top-left {
        width: 100px;
        height: 100px;
        left: -40px;
        top: -60px;
    }

    .circle-bottom-left {
        width: 150px;
        height: 130px;
        left: -70px;
        bottom: -90px;
    }

    .pattern-top-center {
        top: 10px;
        background-size: 70% auto;
    }

    .pattern-top-right {
        right: 10px;
        top: 10px;
        background-size: 100% auto;
        width: 20px;
        height: 60px;
    }

    .pattern-bottom-right+.pattern-bottom-right {
        background-size: 50px auto;
    }

    .checkbox,
    .radiobox {
        font-size: 13px;
        padding-left: 24px;
    }

    .forgot-link {
        font-size: 13px;
    }

    .link-box p {
        font-size: 13px;
    }

    /*My Dashboard*/
    .dashboard-logo {
        padding: 0 10px;
        min-width: 170px;
        max-width: 170px;
    }

    .dash-head-right {
        padding: 0 10px;
    }

    .dashboard-sidebar .navbar-toggler {
        position: fixed;
        left: 175px;
        top: 16px;
    }

    .head-profile .dropdown-toggle:after,
    .head-profile .profile-name {
        display: none;
    }

    .head-profile .dropdown-menu:before {
        left: auto;
        right: 6px;
    }

    .profile-link .head-icon-link {
        margin-right: 0;
    }

    .head-icon-link {
        min-width: 30px;
        max-width: 30px;
        height: 30px;
    }

    .profile-link .head-icon-link {
        border: 0;
        padding: 0;
    }

    .dash-title-block-inn {
        flex-wrap: wrap;
    }

    .head-title-box h4,
    .dash-title-block h4 {
        font-size: 16px;
        line-height: 22px;
    }

    .card-boxes {
        padding: 20px 10px;
    }

    .card-boxes h4 {
        font-size: 14px;
        line-height: 20px;
    }

    .card-boxes .iconbox {
        margin-bottom: 10px;
    }

    .text-link {
        font-size: 14px;
    }

    .text-link i {
        margin-left: 3px;
        font-size: 16px;
    }

    .review-list .sub-items+.sub-items {
        margin-top: 18px;
    }

    .input-field {
        min-width: 100%;
        max-width: 100%;
    }

    .modal-header {
        padding: 15px 30px;
    }

    .modal-body {
        padding: 30px;
    }

    .product-list {
        margin: 0 -10px;
    }

    .product-list .items {
        padding: 0 10px;
        margin: 0 0 20px;
        min-width: 50%;
        max-width: 50%;
    }

    .breadcrumb-list li {
        font-size: 13px;
    }

    .breadcrumb-list li+li:before {
        margin: 0 2px;
    }

    .box-title {
        flex-wrap: wrap;
    }

    .box-title h3 {
        order: 2;
    }

    .box-title .breadcrumb-block {
        min-width: 100%;
        order: 1;
        margin: 0 0 10px;
    }

    .table-block .table td {
        padding: 8px 10px;
        font-size: 14px;
        line-height: 20px;
    }

    .table-block .table td:first-child {
        width: 100px;
    }

    .calendar_style .fc-theme-standard .fc-col-header .fc-col-header-cell-cushion {
        font-size: 12px;
        line-height: 16px;
    }

    .calendar_style thead .fc-col-header-cell .fc-scrollgrid-sync-inner {
        padding: 10px 0;
    }

    .calendar_style .fc .fc-daygrid-day-frame {
        min-height: 60px;
    }

    .calendar_style .fc .fc-daygrid-day-number {
        right: 2px;
        top: 2px;
    }

    .calendar_style .fc .fc-button-primary {
        font-size: 12px;
    }

    .calendar_style .fc .fc-toolbar-title {
        font-size: 16px;
    }

    .fc-daygrid-event-harness .fc-daygrid-event {
        padding: 5px;
    }

    .fc-daygrid-event-harness .fc-daygrid-event img {
        max-width: 12px;
    }

    .avail_app {
        font-size: 60%;
        line-height: 22px;
        letter-spacing: -1px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .drag-drop-box {
        font-size: 14px;
    }

    .cart-blocks .right-block {
        margin-top: 20px;
    }

    .cart-list-wrapper,
    .cart-total-wrapper {
        padding: 20px;
    }

    .cart-items {
        flex-wrap: wrap;
    }

    .cart-imgbox {
        min-width: 120px;
        max-width: 120px;
        margin: 0 auto 20px;
    }

    .cart-label,
    .cart-value {
        font-size: 14px;
    }

    .card-address {
        padding: 20px;
    }

    .box-title .right-block {
        margin-top: 10px;
    }

    .box-title .right-block .filter-field-box {
        min-width: 100%;
        flex-wrap: wrap;
    }

    .filter-field-box .label {
        margin: 0 0 10px;
    }

    .pagination-block {
        flex-wrap: wrap;
        text-align: center;
    }

    .result-text {
        width: 100%;
    }

    .pagination-block .pagination {
        min-width: 100%;
        margin-top: 15px;
        justify-content: center;
    }

    /*.chat-form {
    position: fixed; left: 15px; right: 15px; bottom: 0;
}*/
    .btnbox.option-btn {
        flex-wrap: wrap;
    }

    .btnbox.option-btn .btn {
        min-width: 200px;
    }


}

/*767 End*/
@media screen and (max-width:374px) {

    .head-profile,
    .dash-head-iconbox,
    .head-icon-link+.head-icon-link {
        margin-left: 5px;
    }
}

.fc-button:focus,
.fc-button:active {
    border: none;
    box-shadow: none !important;
}

.account-blocks table td .red-btn:focus {
    background: #7D003C;
    border-color: #7D003C;
}

.edit-add-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -8px;
    top: 0;
    bottom: 0;
    z-index: 3;
    margin: auto !important;
    width: 30px;
    height: 30px;
    font-size: 14px;
    color: #7d003c;
    background: #fff;
    border: 2px solid rgba(125, 0, 60, 0.3);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.photo-gallary .upload-imgs {
    flex-wrap: wrap;
}

.photo-gallary .upload-imgs .imgbox {
    min-width: 19%;
    max-width: 19%;
    padding: 0px 0 15%;
}

.photo-gallary .upload-imgs .imgbox i {
    right: -5px;
    top: -5px;

}

/* .ti-footer{
    display: none;
} */